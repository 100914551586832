import React, { useState } from 'react'

import { Redirect, useParams } from 'react-router-dom'

import styled from '@emotion/styled'

import { getQuestionsBodies, PageHeader, useFormContext } from '@fwl/shared'

import PulseQuestionForm from '../components/PulseQuestionForm'
import { useStore } from '../useStore'

const StyledContent = styled.div``

const EditPulseQuestionView = () => {
  const { handleSubmit } = useFormContext()

  const store = useStore()
  const questions = useStore((state) => state.questions)
  const { id: rawId } = useParams()
  const id = parseInt(rawId, 10)

  const [pulseQuestion, setPulseQuestion] = useState(() =>
    questions.find(({ id: target }) => target === id)
  )

  const [updated, setUpdated] = useState(false)

  const updateQuestion = handleSubmit(async (formData) => {
    const body = getQuestionsBodies({ questions: pulseQuestion.body, formData })

    await store.updatePulseQuestion({
      id: pulseQuestion.id,
      title: body[0].question,
      body,
    })

    setUpdated(true)
  })

  if (updated) {
    return <Redirect to="/pulse/questions" />
  }

  return (
    <StyledContent>
      <PageHeader
        title="Edit pulse question"
        breadcrumbs={[
          { to: '/pulse/questions', label: 'Pulse questions' },
          { to: '/pulse/questions/create', label: 'Edit pulse question' },
        ]}
      />
      <PulseQuestionForm
        pulseQuestion={pulseQuestion}
        updatePulseQuestion={setPulseQuestion}
        onSubmit={updateQuestion}
      />
    </StyledContent>
  )
}

export default EditPulseQuestionView
