import React from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  Alert,
  BREAKPOINTS,
  Button,
  Modal,
  mq,
  Panel,
  PopoverMenu,
  useAuth,
} from '@fwl/shared'

import RenderSurveyPreview from '../components/RenderSurveyPreview'
import { useStore } from '../useStore'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${mq(BREAKPOINTS.sm)} {
    flex-direction: row;
  }
`

const Description = styled.div`
  margin-top: 1rem;
  opacity: 0.5;
  line-height: 150%;
`

const Title = styled.div`
  font-size: 1.2rem;
  line-height: 150%;
`

const Meta = styled.ul`
  list-style: none;
  font-size: 0.8rem;
  margin-top: 1.5rem;
  padding: 0;
  opacity: 0.5;
`

const MetaEntry = styled.li`
  display: inline-block;
  margin-right: 0.5em;

  &:not(:last-child):after {
    content: '•';
    display: inline-block;
    margin-left: 0.5em;
  }
`

const Tools = styled.div`
  display: flex;
  align-items: start;
  margin-top: 1rem;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }

  ${mq(BREAKPOINTS.sm)} {
    min-width: 170px;
    margin-top: 0;
    margin-left: auto;
    justify-content: end;
  }
`

const SurveyEntry = ({ survey }) => {
  const {
    id,
    title,
    description,
    body: questions,
    isLaunched,
    onlyAvailableFor: surveyAccount,
  } = survey

  const {
    profile: { isStaff },
  } = useAuth()
  const deleteSurvey = useStore((store) => store.deleteSurvey)
  const managedAccounts = useStore((store) => store.managedAccounts)

  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    React.useState(false)

  const [deleteError, setDeleteError] = React.useState(null)

  const getAccountName = (accountId) => {
    const account = managedAccounts.find((account) => account.id === accountId)
    return account.name
  }

  const closeModal = () => {
    setIsDeleteConfirmationOpen(false)
    setDeleteError(null)
  }

  const handleDelete = async (surveyId) => {
    try {
      await deleteSurvey(surveyId)
      closeModal()
    } catch (error) {
      console.dir(error)
      setDeleteError(error)
    }
  }

  const isGeneralSurvey = !surveyAccount
  const shouldShowSurveyActions = isGeneralSurvey ? isStaff : true

  return (
    <Panel key={id}>
      <Container>
        <div>
          <Title>{title}</Title>
          <Description className="description">{description}</Description>
          <Meta>
            <MetaEntry>
              {isGeneralSurvey ? 'General' : getAccountName(surveyAccount)}
            </MetaEntry>
            <MetaEntry>{questions.length} question(s)</MetaEntry>
            <MetaEntry>{isLaunched ? 'Launched' : 'Not Launched'}</MetaEntry>
          </Meta>
        </div>
        <Tools>
          <Button onClick={() => setIsPreviewOpen(true)}>Preview</Button>

          {shouldShowSurveyActions && (
            <PopoverMenu>
              <div>
                <Button
                  as={isLaunched ? null : Link}
                  to={isLaunched ? null : `/surveys/${id}`}
                  disabled={isLaunched}
                  appearance="outline"
                  block
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    setIsDeleteConfirmationOpen(true)
                  }}
                  disabled={isLaunched}
                  appearance="outline"
                  block
                >
                  Delete
                </Button>
              </div>
            </PopoverMenu>
          )}
        </Tools>
      </Container>

      {isDeleteConfirmationOpen && (
        <Modal onClose={closeModal}>
          <Panel>
            <h2>Delete Survey</h2>
            {deleteError && <Alert>An Error happened. Please try again!</Alert>}

            <p>
              Please confirm deleting <strong>{title}</strong>
            </p>
            <Button onClick={() => handleDelete(id)} appearance="danger">
              Confirm
            </Button>
          </Panel>
        </Modal>
      )}

      {isPreviewOpen && (
        <RenderSurveyPreview
          questions={questions}
          close={() => setIsPreviewOpen(false)}
        />
      )}
    </Panel>
  )
}

export default SurveyEntry
