import React, { useState } from 'react'

import { Redirect } from 'react-router-dom'

import {
  defaultQuestionConfig,
  generateId,
  getQuestionsBodies,
  PageHeader,
  questionTypes,
  useFormContext,
} from '@fwl/shared'

import PulseQuestionForm from '../components/PulseQuestionForm'
import { useStore } from '../useStore'

const CreatePulseQuestionView = () => {
  const { handleSubmit } = useFormContext()

  const store = useStore()
  const areas = useStore((state) => state.areas)

  const [pulseQuestion, setPulseQuestion] = useState({
    category: null,
    isTeamSpecific: false,
    body: [
      {
        ...defaultQuestionConfig,
        ...questionTypes.find(({ type }) => type === 'likert').props,
        id: generateId(),
        immutable: true,
        area: areas[0].slug,
        type: 'likert',
      },
    ],
  })

  const [created, setCreated] = useState(false)

  const createPulseQuestion = handleSubmit(async (formData) => {
    const body = getQuestionsBodies({ questions: pulseQuestion.body, formData })

    await store.createPulseQuestion({
      title: body[0].question,
      body,
    })

    setCreated(true)
  })

  if (created) {
    return <Redirect to="/pulse/questions" />
  }

  return (
    <>
      <PageHeader
        title="Create pulse question"
        breadcrumbs={[
          { to: '/pulse/questions', label: 'Pulse questions' },
          { to: '/pulse/questions/create', label: 'Create pulse question' },
        ]}
      />
      <PulseQuestionForm
        pulseQuestion={pulseQuestion}
        updatePulseQuestion={setPulseQuestion}
        onSubmit={createPulseQuestion}
      />
    </>
  )
}

export default CreatePulseQuestionView
