import React from 'react'

import create from 'zustand'
import createContext from 'zustand/context'

import { createClient, getUserAccount, useAuth } from '@fwl/shared'

const { Provider, useStore } = createContext()

const createStore = (client, profile) =>
  create((set, get) => ({
    questions: null,
    areas: null,
    coreAreas: null,
    surveys: null,
    badges: null,
    account: null,
    managedAccounts: null,

    requestAccounts: async () => {
      const accounts = await client.getAccounts()
      const account = profile.isStaff ? null : getUserAccount(accounts)

      set({ account, managedAccounts: accounts })
    },

    requestBadges: async () => {
      const badges = await client.getBadges()
      set({ badges })
    },
    requestAreas: async () => {
      const areas = await client.getAreas()
      const coreAreas = areas.filter(({ isCoreArea }) => isCoreArea)
      set({ areas, coreAreas })
    },
    requestPulseAreas: async () => {
      const areas = await client.getPulseAreas()
      set({ areas })
    },
    requestPulseQuestions: async () => {
      const questions = await client.getPulseQuestions()
      set({ questions })
    },
    createPulseQuestion: async (question) => {
      const result = await client.createPulseQuestion(question)
      get().requestPulseQuestions()
      return result
    },
    updatePulseQuestion: async (question) => {
      const result = await client.updatePulseQuestion(question)
      get().requestPulseQuestions()
      return result
    },

    requestSurveys: async () => {
      const surveys = await client.getSurveys()
      set({ surveys })
    },
    createSurvey: async (survey) => {
      const result = await client.createSurvey(survey)
      get().requestSurveys()
      return result
    },
    updateSurvey: async (survey) => {
      await client.updateSurvey(survey)
      get().requestSurveys()
    },
    deleteSurvey: async (surveyId) => {
      await client.deleteSurvey(surveyId)
      get().requestSurveys()
    },
  }))

const StoreProvider = ({ children, baseURL }) => {
  const { profile, token, logOut } = useAuth()
  const client = createClient({ token, baseURL, logOut })

  return (
    <Provider createStore={() => createStore(client, profile)}>
      {children}
    </Provider>
  )
}

export { StoreProvider, useStore }
