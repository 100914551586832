import React, { useEffect, useRef, useState } from 'react'

import {
  BREAKPOINTS,
  MobileHeader,
  Page,
  theme,
  useOutsideClick,
  useWindowSize,
} from '@fwl/shared'

import { useStore } from '../useStore'

import AppRouter from './AppRouter'
import Sidebar from './Sidebar'

const App = ({ ...props }) => {
  const store = useStore()
  const areas = useStore((state) => state.areas)
  const categories = useStore((state) => state.categories)
  const questions = useStore((state) => state.questions)
  const badges = useStore((state) => state.badges)

  const { width } = useWindowSize()

  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const sidebarRef = useRef()
  const isDesktop = width > theme.spacing.breakpoints[BREAKPOINTS.md]

  const closeSidebar = () => {
    if (!isDesktop && isSidebarOpen) {
      setIsSidebarOpen(false)
    }
  }

  useOutsideClick(sidebarRef, closeSidebar)

  const styleVariables = {
    [`--menu-offset`]: isSidebarOpen ? '16rem' : '0rem',
    [`--page-padding`]: isDesktop ? '2.5rem' : '1.5rem',
  }

  useEffect(() => {
    setIsSidebarOpen(width >= 768)
  }, [width])

  useEffect(() => {
    store.requestAccounts()
    store.requestAreas()
    store.requestPulseQuestions()
    store.requestBadges()
  }, [])

  if ([areas, questions, categories, badges].includes(null)) {
    return 'Loading ...'
  }

  return (
    <div style={styleVariables} {...props}>
      <Sidebar
        closeSidebar={closeSidebar}
        isOpen={isSidebarOpen}
        ref={sidebarRef}
      />
      <Page
        dark
        withSidebar
        pre={
          <MobileHeader
            toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
            isOpen={isSidebarOpen}
          />
        }
      >
        <AppRouter />
      </Page>
    </div>
  )
}

App.displayName = 'App'

export default App
