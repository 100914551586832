import React, { useState } from 'react'

import { Button, Modal, Panel, SurveyPreview, Title } from '@fwl/shared'

const RenderSurveyPreview = ({ questions, close }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const submit = () => setIsSubmitted(true)

  return (
    <Modal onClose={close}>
      <Panel>
        {isSubmitted && (
          <>
            <Title title="Thank you!" />
            <p>The survey has been submitted!</p>
            <Button appearance="dark" onClick={close}>
              Close
            </Button>
          </>
        )}
        {!isSubmitted && (
          <SurveyPreview questions={questions} submit={submit} />
        )}
      </Panel>
    </Modal>
  )
}

export default RenderSurveyPreview
