import React from 'react'

import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { LoginRequired } from '@fwl/shared'
import ThemeProvider from '@fwl/shared/components/ThemeProvider'

import App from './components/App'
import { StoreProvider } from './useStore'

const AppWrapper = () => (
  <ThemeProvider>
    <LoginRequired baseURL={API_URL}>
      <StoreProvider baseURL={API_URL}>
        <Router>
          <App />
        </Router>
      </StoreProvider>
    </LoginRequired>
  </ThemeProvider>
)

render(<AppWrapper />, document.getElementById('app'))
