import React from 'react'

import styled from '@emotion/styled'

import { PageHeader } from '@fwl/shared'

const StyledContent = styled.div``

const HomeView = () => (
  <StyledContent>
    <PageHeader title="Admin overview" />
    <p>Active pulse surveys.</p>
    <p>Response activity (today, this week, this month)</p>
  </StyledContent>
)

export default HomeView
