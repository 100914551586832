import React, { useState } from 'react'

import styled from '@emotion/styled'

import { Button, QuestionGroupEditor } from '@fwl/shared'

import { useStore } from '../useStore'

import Controls from './Controls'
import RenderSurveyPreview from './RenderSurveyPreview'

const StyledPulseQuestionForm = styled.div`
  position: relative;
`

const PulseQuestionForm = ({
  pulseQuestion,
  updatePulseQuestion,
  onSubmit,
}) => {
  const areas = useStore((state) => state.coreAreas)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)

  const updateQuestions = (questions) => {
    updatePulseQuestion({
      ...pulseQuestion,
      body: questions,
    })
  }

  return (
    <StyledPulseQuestionForm>
      <QuestionGroupEditor
        questions={pulseQuestion.body}
        updateQuestions={updateQuestions}
        areas={areas}
      />

      <Controls>
        <Button onClick={() => setIsPreviewOpen(true)}>Preview</Button>
        <Button appearance="dark" onClick={() => onSubmit(pulseQuestion)}>
          Save
        </Button>
      </Controls>

      {isPreviewOpen && (
        <RenderSurveyPreview
          questions={pulseQuestion.body}
          close={() => setIsPreviewOpen(false)}
        />
      )}
    </StyledPulseQuestionForm>
  )
}

PulseQuestionForm.displayName = 'PulseQuestionForm'

export default PulseQuestionForm
