import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  BREAKPOINTS,
  Button,
  mq,
  PageHeader,
  Panel,
  PopoverMenu,
} from '@fwl/shared'

import RenderSurveyPreview from '../components/RenderSurveyPreview'
import { useStore } from '../useStore'

const QuestionOverview = styled(Panel)`
  display: flex;
  flex-direction: column;

  ${mq(BREAKPOINTS.sm)} {
    flex-direction: row;
  }

  .meta {
    margin-bottom: 1rem;
    display: flex;
    gap: 0.5rem;

    .area,
    .team-specific {
      display: block;
      background: #f2f2f2;
      font-size: 0.9rem;
      font-weight: 500;
      padding: 0.3rem 0.5rem;
      border-radius: 0.2rem;
    }
  }

  .title {
    font-size: 1.2rem;
    line-height: 150%;
  }

  .questions {
    font-size: 0.8rem;
    margin: 1.5rem 0 0.5rem;
    opacity: 0.5;
  }
`

const Tools = styled.div`
  display: flex;
  align-items: start;
  margin-top: 1rem;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }

  ${mq(BREAKPOINTS.sm)} {
    min-width: 170px;
    margin-top: 0;
    margin-left: auto;
    justify-content: end;
  }
`

const HomeView = () => {
  const requestPulseQuestions = useStore((store) => store.requestPulseQuestions)
  const questions = useStore((state) => state.questions)
  const areas = useStore((state) => state.areas)

  const [isPreviewOpen, setIsPreviewOpen] = useState(null)

  useEffect(() => {
    requestPulseQuestions()
  }, [requestPulseQuestions])

  return (
    <>
      <PageHeader
        title="Pulse questions"
        extra={
          <Button as={Link} appearance="dark" to="/pulse/questions/create">
            Create question
          </Button>
        }
      />

      {questions.map(({ title, id, area, isTeamSpecific, body }) => (
        <QuestionOverview key={id}>
          <div>
            <div className="meta">
              <div className="area">
                {areas.find(({ id: areaId }) => areaId === area.id).title}
              </div>
              {isTeamSpecific && <div className="team-specific">Team-only</div>}
            </div>
            <div className="title">{title}</div>
            <div className="questions">{body.length} question(s)</div>
          </div>
          <Tools>
            <Button onClick={() => setIsPreviewOpen(id)}>Preview</Button>
            <PopoverMenu>
              <div>
                <Button
                  as={Link}
                  to={`/pulse/questions/${id}`}
                  appearance="outline"
                >
                  Edit
                </Button>
              </div>
            </PopoverMenu>
          </Tools>
        </QuestionOverview>
      ))}

      {isPreviewOpen && (
        <RenderSurveyPreview
          questions={questions.find(({ id }) => id === isPreviewOpen).body}
          close={() => setIsPreviewOpen(false)}
        />
      )}
    </>
  )
}

export default HomeView
