import React from 'react'

import { Route, Switch } from 'react-router-dom'

import { Form } from '@fwl/shared'

import { useStore } from '../useStore'
import CreatePulseQuestionView from '../views/CreatePulseQuestionView'
import CreateSurveyView from '../views/CreateSurveyView'
import EditPulseQuestionView from '../views/EditPulseQuestionView'
import EditSurveyView from '../views/EditSurveyView'
import HomeView from '../views/HomeView'
import PulseQuestionsView from '../views/PulseQuestionsView'
import SurveysView from '../views/SurveysView'

const AppRouter = () => {
  const categories = useStore((state) => state.categories)

  if (categories === null) {
    return 'Loading ...'
  }

  return (
    <>
      <Switch>
        <Route exact path="/">
          <HomeView />
        </Route>
        <Route exact path="/pulse/questions">
          <PulseQuestionsView />
        </Route>
        <Route exact path="/pulse/questions/create">
          <Form>
            <CreatePulseQuestionView />
          </Form>
        </Route>
        <Route exact path="/pulse/questions/:id">
          <Form>
            <EditPulseQuestionView />
          </Form>
        </Route>
        <Route exact path="/surveys">
          <SurveysView />
        </Route>
        <Route exact path="/surveys/create">
          <Form>
            <CreateSurveyView />
          </Form>
        </Route>
        <Route exact path="/surveys/:id">
          <Form>
            <EditSurveyView />
          </Form>
        </Route>
      </Switch>
    </>
  )
}

export default AppRouter
