import React, { useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import SurveyForm from '../components/SurveyForm'
import { useStore } from '../useStore'

const EditSurveyView = () => {
  const history = useHistory()
  const { id } = useParams()

  const requestSurveys = useStore((store) => store.requestSurveys)
  const surveys = useStore((store) => store.surveys)
  const currentSurvey =
    surveys && surveys.find((survey) => survey.id === Number(id))

  useEffect(() => {
    if (!surveys) {
      requestSurveys()
    }

    if (surveys && !currentSurvey) {
      history.replace('/surveys/')
    }
  }, [history, surveys, currentSurvey, requestSurveys])

  if (!currentSurvey) {
    return null
  }

  return <SurveyForm survey={currentSurvey} pageTitle="Edit survey" />
}

export default EditSurveyView
