import styled from '@emotion/styled'

import { BREAKPOINTS, mq } from '@fwl/shared'

const Container = styled.div`
  position: sticky;
  margin-top: var(--page-padding);
  bottom: 0;

  z-index: 1;
`

const Content = styled.div`
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: calc(-1 * var(--page-padding));
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  background: #fffe;
  border-top: 1px solid #f8f8f8;
  box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.02);

  ${mq(BREAKPOINTS.md)} {
    width: calc(100vw - var(--menu-offset));
    left: calc(50% + var(--menu-offset) / 2);
  }
`
const Controls = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  )
}

export default Controls
