import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import { Button, PageHeader } from '@fwl/shared'

import SurveyEntry from '../components/SurveyEntry'
import { useStore } from '../useStore'

const HomeView = () => {
  const requestSurveys = useStore((store) => store.requestSurveys)
  const surveys = useStore((state) => state.surveys)

  useEffect(() => {
    requestSurveys()
  }, [requestSurveys])

  return (
    <>
      <PageHeader
        title="Surveys"
        extra={
          <Button as={Link} appearance="dark" to="/surveys/create">
            Create survey
          </Button>
        }
      />

      {surveys === null
        ? 'Loading ...'
        : surveys.map((survey) => (
            <SurveyEntry key={survey.id} survey={survey} />
          ))}
    </>
  )
}

export default HomeView
