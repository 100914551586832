import React, { forwardRef } from 'react'

import { NavLink } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  Icon,
  Logo,
  MenuLink,
  Sidebar as SidebarWrapper,
  useAuth,
} from '@fwl/shared'

import { useStore } from '../useStore'

const Header = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.primary.fade1};
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  a {
    display: block;
    margin: 0 auto;
  }
`

const NavLogo = styled(Logo)`
  width: ${({ isMinimal }) => (isMinimal ? '100%' : '9rem')};
  height: auto;
  margin: 0 auto;
  color: ${({ theme }) => theme.primary.contrast};
`

const Sidebar = (props, ref) => {
  const { profile, logOut } = useAuth()
  const account = useStore((state) => state.account)

  return (
    <SidebarWrapper
      {...props}
      profile={profile}
      account={account}
      logOut={logOut}
      ref={ref}
    >
      <Header>
        <NavLink to="/">
          <NavLogo />
        </NavLink>
      </Header>
      <MenuLink to="/" exact>
        <Icon icon="dashboard" />
        Overview
      </MenuLink>
      <div className="divider" />
      <MenuLink to="/pulse/questions">
        <Icon icon="poll" />
        Pulse questions
      </MenuLink>
      <MenuLink to="/surveys">
        <Icon icon="ballot" />
        Surveys
      </MenuLink>
    </SidebarWrapper>
  )
}

export default forwardRef(Sidebar)
