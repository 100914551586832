import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  Button,
  ErrorMessage,
  Field,
  getQuestionsBodies,
  Input,
  PageHeader,
  Panel,
  QuestionGroupEditor,
  Select,
  TextArea,
  Title,
  useAuth,
  useFormContext,
} from '@fwl/shared'

import RenderSurveyPreview from '../components/RenderSurveyPreview'
import { useStore } from '../useStore'

import Controls from './Controls'

const StyledContent = styled.div`
  position: relative;
`

const SurveyForm = ({ survey, pageTitle = 'Create survey' }) => {
  const {
    title,
    description,
    scope: currentScope,
    badge,
    onlyAvailableFor: account,
    body = [],
  } = survey || {}

  const history = useHistory()
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useFormContext()
  const { profile } = useAuth()

  const store = useStore()
  const areas = useStore((state) => state.areas)
  const badges = useStore((state) => state.badges)
  const userAccount = useStore((state) => state.account)
  const managedAccounts = useStore((state) => state.managedAccounts)

  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [questions, setQuestions] = useState(body)

  const availableAreas = areas.filter(({ scope, isCoreArea }) => {
    const selectedScope = watch('scope') || currentScope || 'organization'
    const isMatchingSelectedScope = scope === selectedScope

    return !isCoreArea && isMatchingSelectedScope
  })

  const submitQuestion = handleSubmit(async (formData) => {
    const { scope, title, description, badge, account } = formData
    const surveyQuestions = getQuestionsBodies({ questions, formData })

    // Use the account selected by staff users, otherwise use the current user associated account
    const associatedAccount = profile.isStaff ? account : userAccount?.id

    const operationFunction = survey ? store.updateSurvey : store.createSurvey
    const operationParams = {
      ...(survey?.id && { id: survey.id }),
      scope,
      title,
      description,
      badge,
      body: surveyQuestions,
      onlyAvailableFor: associatedAccount,
    }

    await operationFunction(operationParams)
    history.replace('/surveys/')
  })

  return (
    <StyledContent>
      <PageHeader
        title={pageTitle}
        breadcrumbs={[
          { to: '/surveys', label: 'Surveys' },
          { to: '/surveys/create', label: pageTitle },
        ]}
        extra={<></>}
      />
      <Panel withMargin>
        <Title title="Survey" />
        <Field label="Survey title">
          <Input
            placeholder="Enter title ..."
            defaultValue={title}
            hasError={!!errors.title}
            {...register('title', { required: 'Title must be provided' })}
          />
          <ErrorMessage errors={errors} fieldName="title" />
        </Field>
        <Field label="Description">
          <TextArea
            placeholder="Enter description ..."
            defaultValue={description}
            hasError={!!errors.description}
            {...register('description', {
              required: 'Description must be provided',
            })}
          />
          <ErrorMessage errors={errors} fieldName="description" />
        </Field>
        {profile.isStaff && managedAccounts && (
          <Field label="Account">
            <Select {...register('account')} defaultValue={account}>
              <option value="">All</option>
              {managedAccounts.map((account) => (
                <option value={account.id} key={account.id}>
                  {account.name}
                </option>
              ))}
            </Select>
          </Field>
        )}
        <Title title="Scope" />
        <Select
          disabled={questions.length > 0}
          defaultValue={currentScope}
          {...register('scope')}
        >
          <option value="organization">Organization</option>
          <option value="team">Team</option>
        </Select>
        <Title title="badge" />
        <Select {...register('badge')} defaultValue={badge}>
          {badges.map(({ identifier }) => (
            <option key={identifier} value={identifier}>
              {identifier}
            </option>
          ))}
        </Select>
      </Panel>

      <QuestionGroupEditor
        questions={questions}
        updateQuestions={setQuestions}
        areas={availableAreas}
      />

      <Controls>
        <Button
          onClick={() => setIsPreviewOpen(true)}
          disabled={questions.length < 1}
        >
          Preview
        </Button>
        <Button appearance="dark" onClick={submitQuestion}>
          Save
        </Button>
      </Controls>

      {isPreviewOpen && (
        <RenderSurveyPreview
          questions={questions}
          close={() => setIsPreviewOpen(false)}
        />
      )}
    </StyledContent>
  )
}

export default SurveyForm
